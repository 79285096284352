.results-page {
  background-color: rgba(17, 17, 17, 0.1);
  bottom: 0;
  color: rgb(255, 240, 220);
  height: 100vh;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2rem;
  padding-bottom: 88px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.results-list {
  margin: 0 auto;
  margin-bottom: 1rem;
  max-width: 800px;
  padding: 0 1rem;
  padding-top: 0;
  position: relative;
  z-index: 1;
  width: 90%;
  transform: translate(-2%,0);
}

.result-item {
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(52, 40, 30, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  display: flex;
  height: 80px;
  margin-bottom: 0.5rem;
  padding: 1rem;
  transition: background-color 0.2s;
  width: 90%;
}

.result-item:hover {
  background-color: rgba(42, 30, 20, 0.85);
  border-color: rgba(255, 240, 220, 0.3);
}

.result-artwork {
  flex-shrink: 0;
  height: 60px;
  margin-right: 1rem;
  position: relative;
  width: 60px;
}

.result-image {
  border-radius: 4px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.play-button {
  align-items: center;
  background-color: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 50%;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 50%;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease;
  width: 36px;
}

.result-artwork:hover .play-button {
  opacity: 1;
}

.play-button:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
  opacity: 1;
}

.play-button.playing {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
  opacity: 1;
}

.play-button.loading {
  cursor: wait;
  opacity: 0.5;
}

.play-icon {
  height: 20px;
  width: 20px;
}

from {
  opacity: 0;
  transform: rotate(0deg);
}

to {
  opacity: 1;
  transform: rotate(360deg);
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.result-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: "Courier New", monospace;
  min-width: 0;
}

.result-title {
  color: rgb(255, 240, 220);
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-artist {
  color: rgba(255, 240, 220, 0.7);
  font-size: 0.9rem;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-album {
  color: rgba(255, 240, 220, 0.5);
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.result-score {
  background-color: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 1rem;
  padding: 4px 8px;
  white-space: nowrap;
}

.back-button {
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: rgba(52, 40, 30, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  font-family: "Courier New", monospace;
  font-size: 1rem;
  font-weight: 500;
  gap: 0.5rem;
  left: 2rem;
  padding: 0.75rem 1.5rem;
  position: fixed;
  top: 2rem;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}

.back-button:hover {
  background-color: rgba(42, 30, 20, 0.85);
  border-color: rgba(255, 240, 220, 0.3);
  transform: translateY(-1px);
}

.back-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.results-page::-webkit-scrollbar {
  width: 8px;
}

.results-page::-webkit-scrollbar-track {
  background: transparent;
}

.results-page::-webkit-scrollbar-thumb {
  background-color: rgba(255, 240, 220, 0.2);
  border-radius: 4px;
}

.results-page::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 240, 220, 0.3);
}

.result-actions {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-left: auto;
}

.similar-button {
  align-items: center;
  background-color: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  font-family: "Courier New", monospace;
  font-size: 0.8rem;
  font-weight: 500;
  justify-content: center;
  min-width: 100px;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.similar-button:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.similar-button.loading {
  cursor: wait;
  opacity: 0.7;
}

.similar-icon {
  height: 16px;
  width: 16px;
}

.reference-song-container {
  backdrop-filter: blur(10px);
  background-color: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.3);
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 4rem;
  max-width: 700px;
  padding: 2rem 1rem;
}

.reference-song-header {
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.reference-song {
  align-items: center;
  background-color: rgba(52, 40, 30, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.reference-artwork {
  flex-shrink: 0;
  height: 80px;
  margin-right: 1.5rem;
  position: relative;
  width: 80px;
}

.reference-image {
  border-radius: 4px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.reference-info {
  flex-grow: 1;
  font-family: "Courier New", monospace;
  min-width: 0;
}

.reference-title {
  background-color: rgba(42, 30, 20, 0.5);
  border-bottom: 1px solid rgba(255, 240, 220, 0.1);
  color: rgba(255, 240, 220, 0.7);
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.reference-artist {
  color: rgba(255, 240, 220, 0.7);
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reference-album {
  color: rgba(255, 240, 220, 0.5);
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.results-section {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 1rem;
  padding-bottom: 14rem;
}

.results-header {
  align-items: center;
  color: #d8c99b;
  display: flex;
  font-family: "Courier New", monospace;
  font-size: 1.2em;
  font-weight: 500;
  justify-content: space-between;
  margin: 4rem 0 1rem;
  margin-bottom: 20px;
  padding-left: 1rem;
}

.reference-container {
  margin: 0 auto;
  margin-bottom: 1rem;
  margin-top: 5rem;
  max-width: 800px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.reference-header {
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(52, 40, 30, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  overflow: hidden;
  padding: 1rem;
  width: 90%;
}

.reference-song-title {
  color: rgb(255, 240, 220);
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.results-container {
  margin: 0 auto;
  max-width: 800px;
}

.filter-container {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 8px;
  margin-top: -50px;
  padding: 0px;
  position: absolute;
  z-index: 10;
  left: calc(50% - 180px);
  width: 100%;
}

.no-results {
  color: #d8c99b;
  font-size: 1.2em;
  margin-top: 2em;
  text-align: center;
}

.loading-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 60vh;
  justify-content: center;
}

.loading-icon {
  color: #ffffff;
  height: 48px;
  width: 48px;
}

.loading-text {
  color: #ffffff;
  font-size: 1.2rem;
}

.back-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.result-item.loading {
  opacity: 0.7;
}

.play-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.similar-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.loading-icon.animate-spin {
  color: #ffffff;
}

.similar-icon.animate-spin {
  margin-right: 4px;
}

.error-container {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 60vh;
  justify-content: center;
}

.error-message {
  color: #ff6b6b;
  font-size: 1.2rem;
  max-width: 80%;
  text-align: center;
}

.retry-button {
  background-color: rgba(52, 40, 30, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 20px;
  transition: all 0.2s ease;
}

.retry-button:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.video-container {
  background: transparent;
  background-color: rgba(0, 0, 0, 1);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.video-container video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.video-container .noise {
  position: fixed;
  z-index: 1003;
}

.App .noise {
  z-index: 1001;
}

.waveform-container {
  background-color: rgba(42, 30, 20, 0.6);
  border-radius: 4px;
  height: 40px;
  margin-top: 12px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: opacity 0.3s ease;
  width: 100%;
}

.waveform-canvas {
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.waveform-progress {
  background-color: rgba(255, 240, 220, 0.2);
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: width 0.1s linear;
}

.result-item.expanded {
  height: 140px;
}

.result-item.expanded .waveform-container {
  opacity: 1;
}

.show-similar-button {
  align-items: center;
  background-color: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  font-family: "Courier New", monospace;
  font-size: 0.8rem;
  gap: 6px;
  margin-left: 12px;
  padding: 8px 12px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.show-similar-button:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.show-similar-icon {
  height: 14px;
  width: 14px;
}

0% {
  opacity: 0.5;
  transform: scale(0.95);
}

60% {
  opacity: 1;
  transform: scaleY(0.01);
}

75% {
  opacity: 0.8;
  transform: scaleY(0);
}

100% {
  opacity: 0.5;
  transform: scale(0.95);
}

50% {
  opacity: 0.8;
  transform: scale(1.05);
}

.results-page.exiting {
  animation: tvOff 0.4s ease-in-out forwards;
  transform-origin: center center;
}

.results-page.exiting::before {
  animation: tvLine 0.2s ease-in-out forwards;
  background: white;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.1;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center center;
}

.results-search {
  margin: 0 auto;
  width: 500px;
  position: relative; /* Add this */
  z-index: 1000; /* Add this */
}

.results-search .search-wrapper {
  align-items: center;
  display: flex;
  width: 100%;
  position: relative; /* Add this */
}

.results-search .search-input {
  background: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 14px;
  height: 25px;
  padding: 8px 12px;
  text-align: center;
  transition: all 0.2s ease;
  width: 100%;
}

.results-search .search-results {
  background: rgba(42, 30, 20, 0.95);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  left: 0;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
  width: 100%; /* Add this */
}

.results-search .search-result-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  transition: background-color 0.2s;
  gap: 12px; /* Add this */
}

.results-search .search-result-artwork {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.results-search .search-result-info {
  flex: 1;
}

.results-search .search-result-title {
  color: rgb(255, 240, 220);
  font-size: 0.9rem;
  margin-bottom: 2px;
}

.results-search .search-result-artist {
  color: rgba(255, 240, 220, 0.7);
  font-size: 0.8rem;
}

.show-more-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  padding-bottom: 5rem;
}

.show-more-button {
  align-items: center;
  background-color: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  gap: 8px;
  padding: 12px 24px;
  transition: all 0.2s ease;
}

.show-more-button:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.show-more-icon {
  height: 16px;
  width: 16px;
}

.download-status {
  border-radius: 50%;
  height: 8px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 8px;
  z-index: 2;
}

.download-status.loading {
  animation: pulse 1.5s infinite;
}

.download-status.completed {
  background-color: rgba(255, 240, 220, 0.6);
}

.floating-player {
  backdrop-filter: blur(10px);
  background: rgba(17, 17, 17, 0.95);
  background-color: rgba(42, 30, 20, 0.95);
  border-top: 1px solid rgba(255, 240, 220, 0.2);
  bottom: 0;
  font-family: "Courier New", monospace;
  left: 0;
  margin-bottom: 80px;
  padding: 1.5rem;
  position: fixed;
  right: 0;
  z-index: 9999;
}

.progress-bar {
  background: rgba(255, 240, 220, 0.1);
  background-color: rgba(255, 240, 220, 0.1);
  border-radius: 2px;
  cursor: pointer;
  height: 4px;
  overflow: hidden;
  position: relative;
}

.progress-bar-fill {
  background: rgba(255, 240, 220, 0.6);
  background-color: rgba(255, 240, 220, 0.6);
  border-radius: 2px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
}

.progress-handle {
  background: rgb(255, 240, 220);
  border-radius: 50%;
  cursor: grab;
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
}

.progress-handle:active {
  cursor: grabbing;
}

.loading-indicator {
  align-items: center;
  display: flex;
  justify-content: center;
}

.player-container {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin: 0 auto;
  max-width: 800px;
}

.player-controls {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.player-play-button {
  align-items: center;
  background-color: rgba(52, 40, 30, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 50%;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: all 0.2s ease;
  width: 40px;
}

.player-play-button:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.player-artwork {
  border-radius: 4px;
  height: 48px;
  overflow: hidden;
  width: 48px;
}

.player-artwork img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.player-info {
  flex: 1;
  min-width: 0;
}

.player-title {
  color: rgb(255, 240, 220);
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.player-artist {
  color: rgba(255, 240, 220, 0.7);
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.player-progress {
  flex: 1;
  margin: 0 1rem;
}

.progress-bar:hover .progress-bar-fill {
  background-color: rgba(255, 240, 220, 0.8);
}

.player-time {
  color: rgba(255, 240, 220, 0.6);
  font-family: "Courier New", monospace;
  font-size: 0.8rem;
  min-width: 80px;
  text-align: right;
  white-space: nowrap;
}

.floating-player .player-main-controls {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  width: 50%;
}

@media (max-width: 768px) {

  .results-page.mobile {
    padding: 1rem 0.5rem;
    padding-top: 4rem;
  }

  .back-button.mobile {
    align-items: center;
    background-color: rgba(52, 40, 30, 0.95);
    border-radius: 50%;
    display: flex;
    height: 36px;
    justify-content: center;
    left: 1rem;
    padding: 0.5rem;
    top: 1rem;
    width: 36px;
  }

  .back-button.mobile .back-icon {
    height: 20px;
    width: 20px;
  }

  .results-search.mobile {
    left: 0;
    margin-top: 80px;
    position: fixed;
    top: 1rem;
    width: 90%;
    z-index: 20;
    transform: translate(5%,0);
  }

  .results-search.mobile .search-input {
    font-size: 14px;
    height: 22px;
    padding: 8px 36px;
  }

  .results-search.mobile .search-results {
    margin: 0 0.5rem;
    max-height: 60vh;
  }

  .reference-container.mobile {
    margin-top: 5rem;
    width: 90%;
    transform: translate(-3%,0);
  }

  .reference-header.mobile {
    padding: 0.5rem;
    width: 100%;
  }

  .reference-song.mobile {
    flex-direction: row;
    gap: 0.75rem;
    padding: 0.75rem;
  }

  .reference-song.mobile .reference-artwork {
    height: 56px;
    width: 56px;
  }

  .reference-song.mobile .reference-info {
    flex: 1;
  }
  
  .reference-title.mobile {
    background-color: rgba(42, 30, 20, 0.5);
    border-bottom: 1px solid rgba(255, 240, 220, 0.1);
    color: rgba(255, 240, 220, 0.7);
    font-family: "Courier New", monospace;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    padding: 1rem;
  }
  
  .reference-song-title.mobile {
    color: rgb(255, 240, 220);
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .reference-album.mobile {
    color: rgba(255, 240, 220, 0.5);
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .reference-artist.mobile {
    color: rgba(255, 240, 220, 0.7);
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .result-item.mobile {
    gap: 0.75rem;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    width: 100%;
  }

  .result-item.mobile .result-artwork {
    height: 48px;
    width: 48px;
  }

  .result-item.mobile .play-button {
    height: 32px;
    opacity: 1;
    width: 32px;
  }

  .result-item.mobile .play-icon {
    height: 16px;
    width: 16px;
  }

  .result-item.mobile .result-actions {
    position: absolute;
    right: 0.75rem;
  }

  .floating-player.mobile {
    padding: 0.5rem;
  }

  .floating-player.mobile .player-container {
    flex-direction: column;
    gap: 0.5rem;
  }

  .floating-player.mobile .player-main-controls {
    align-items: center;
    display: flex;
    gap: 0.75rem;
    width: 100%;
  }

  .floating-player.mobile .player-artwork {
    height: 40px;
    width: 40px;
  }

  .floating-player.mobile .player-progress {
    margin: 0.25rem 0;
    width: 100%;
  }

  .floating-player.mobile .player-time {
    font-size: 0.7rem;
    text-align: center;
  }

  .show-more-button.mobile {
    justify-content: center;
    margin: 1rem 0.5rem;
    width: calc(100% - 1rem);
  }

  .filter-container.mobile {
    background: rgba(0, 0, 0, 0.0);
    border-radius: 8px;
    margin-top: -50px;
    left: calc(50% - 150px);
    padding: 0px;
    position: absolute;
    z-index: 10;
  }
}

/* This will position the language toggle on the right */
.language-toggle {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.filter-container {
  background: rgba(0, 0, 0, 0.0);
  border-radius: 8px;
  margin-top: -50px;
  padding: 0px;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
}

.filter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateX(16%);
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .filter-container {
    width: 90%;
  }
  
  
  .filter-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateX(45%);
    padding: 0 1rem;
  }
  
  .filter-container.mobile {
    margin-top: -50px;
  }
}

.crate-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem 0;
}

.crate-select-item {
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: rgba(52, 40, 30, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  font-family: "Courier New", monospace;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  transition: all 0.2s ease;
  width: 100%;
}

.crate-select-item:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.crate-select-item:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.crate-select-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.crate-select-name {
  font-size: 0.9rem;
  font-weight: 500;
}

.crate-select-count {
  color: rgba(255, 240, 220, 0.7);
  font-size: 0.8rem;
}

.no-crates-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  text-align: center;
}

.no-crates-message p {
  color: rgba(255, 240, 220, 0.7);
  font-size: 0.9rem;
}

.action-button {
  align-items: center;
  background-color: rgba(42, 30, 20, 0.9);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: flex;
  font-family: "Courier New", monospace;
  font-size: 0.8rem;
  gap: 6px;
  padding: 8px 12px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.action-button:hover {
  background-color: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.action-icon {
  height: 14px;
  width: 14px;
}

@media (max-width: 768px) {
  .crate-list {
    max-height: 300px;
  }

  .action-button {
    padding: 8px;
  }
  
  .crate-select-item {
    padding: 0.5rem 0.75rem;
  }
}

/* Update the result actions styles */
.result-actions {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-left: auto;
}
