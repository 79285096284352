/* ProfilePage.css */
.profile-page {
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 5rem; /* Account for the nav bar */
}

/* Container styles */
.profile-container {
  position: relative;
  background: rgba(34, 34, 34, 0.98);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  padding: 2rem;
  max-width: 800px;
  margin: 4rem auto;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Profile header */
.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(255, 240, 220, 0.2);
}

.profile-avatar {
  width: 80px;
  height: 80px;
  background: rgba(255, 240, 220, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
}

.profile-info {
  flex: 1;
}

.profile-title {
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.profile-email {
  color: rgba(255, 240, 220, 0.6);
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
}

/* Stats section */
.profile-stats {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 240, 220, 0.1);
  border-radius: 6px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "Courier New", monospace;
}

.stat-label {
  color: rgba(255, 240, 220, 0.6);
}

.stat-value {
  color: rgb(255, 240, 220);
}

/* Premium upgrade section */
.premium-upgrade {
  background: linear-gradient(
    45deg,
    rgba(255, 240, 220, 0.05) 0%,
    rgba(255, 240, 220, 0.1) 100%
  );
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 2rem;
}

.premium-title {
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.premium-features {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem 0;
}

.premium-feature {
  color: rgba(255, 240, 220, 0.8);
  font-family: "Courier New", monospace;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Button styles */
.profile-button {
  width: 100%;
  background: rgba(255, 240, 220, 0.1);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.profile-button:hover:not(:disabled) {
  background: rgba(255, 240, 220, 0.15);
  border-color: rgba(255, 240, 220, 0.3);
}

.profile-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Auth prompt styles */
.auth-prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(34, 34, 34, 0.95);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  max-width: 400px;
  width: 90%;
  backdrop-filter: blur(10px);
}

.auth-prompt-title {
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.auth-prompt-text {
  color: rgba(255, 240, 220, 0.8);
  font-family: "Courier New", monospace;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-container {
    margin: 2rem 1rem;
    padding: 1.5rem;
  }

  .profile-header {
    flex-direction: column;
    text-align: center;
  }

  .profile-avatar {
    margin: 0 0 1rem 0;
  }
}

/* VHS effect overlay */
.profile-page::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.03),
    rgba(0, 0, 0, 0.03) 1px,
    transparent 1px,
    transparent 2px
  );
  pointer-events: none;
}
