/* CratesPage.css */
.crate-results-page {
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding: 6rem 4rem;
  padding-bottom: 88px; /* Account for the floating player */
}

.crate-results-section {
  position: relative;
  background: rgba(34, 34, 34, 0.98);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  padding: -2rem;
  max-width: 1200px;
  margin: 0 auto;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.crate-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 240, 220, 0.2);
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 1.5rem;
}

.create-crate-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 240, 220, 0.1);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.create-crate-button:hover {
  background: rgba(255, 240, 220, 0.15);
  border-color: rgba(255, 240, 220, 0.3);
}

.crate-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
}

.crate-card {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 240, 220, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.crate-card:hover {
  background: rgba(255, 240, 220, 0.05);
  border-color: rgba(255, 240, 220, 0.3);
  transform: translateY(-2px);
}

.crate-info h3 {
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.crate-info p {
  color: rgba(255, 240, 220, 0.6);
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
}

/* VHS effect overlay */
.crate-results-page::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.03),
    rgba(0, 0, 0, 0.03) 1px,
    transparent 1px,
    transparent 2px
  );
  pointer-events: none;
  z-index: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .crate-results-page {
    padding: 2rem 1rem;
  }

  .crate-results-section {
    padding: 1.5rem;
  }

  .crate-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .create-crate-button span {
    display: none;
  }
}

/* Create Crate Modal Styles */
.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.auth-modal {
  background: rgba(34, 34, 34, 0.98);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
}

.auth-modal-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth-input {
  background: rgba(255, 240, 220, 0.1);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  padding: 0.75rem 1rem;
  width: 100%;
}

.auth-input:focus {
  outline: none;
  border-color: rgba(255, 240, 220, 0.4);
}

.auth-submit {
  background: rgba(255, 240, 220, 0.1);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.auth-submit:hover:not(:disabled) {
  background: rgba(255, 240, 220, 0.15);
  border-color: rgba(255, 240, 220, 0.3);
}

.auth-submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.auth-error-message {
  color: #ff6b6b;
  text-align: center;
  margin-bottom: 1rem;
}
