
.cassette-page {
  height: 100%;
  position: relative;
  width: 100%;
}

.search-input {
  background: #222;
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-size: 14px;
  height: 25px;
  padding: 8px 12px;
  text-align: center;
  transition: all 0.2s ease;
  width: 100%;
}

.year-select {
  background: #2b2b2b;
  border: 0px solid #444;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
  color: #e0e0e0;
  font-family: 'Courier New', monospace;
  font-size: 15px;
  height: 30px;
  margin: 8px 0;
  text-align: center;
  width: 200px;
}

.submit-button {
  background: #222;
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
  color: rgb(255, 240, 220);
  cursor: pointer;
  font-family: "Courier New", monospace;
  font-size: 14px;
  height: 40px;
  letter-spacing: 1px;
  padding: 6px;
  transition: opacity 0.2s ease;
  width: 160px;
}

.submit-button:hover:not(:disabled) {
  background: #222222;
  border-color: rgba(255, 240, 220, 0.5);
}

.submit-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.year-wheel {
  background: linear-gradient(145deg, #2b2b2b, #222);
  border-radius: 10px;
  cursor: ns-resize;
  height: 120px;
  width: 80px;
}

.wheel-container {
  background: #222;
  border-radius: 5px;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.wheel {
  height: 100%;
  transition: transform 0.1s ease-out;
  width: 100%;
}

.wheel-numbers {
  height: 100%;
  width: 100%;
}

.year-number {
  color: #ddd;
  font-family: "Digital-7", monospace;
  font-size: 14px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform-origin: center left;
  width: 100%;
}

.wheel-notch {
  background: #ff0000;
  box-shadow: 0 0 5px rgba(255,0,0,0.5);
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
}

.selected-year {
  color: #ff0000;
  font-family: "Digital-7", monospace;
  font-size: 18px;
  padding: 10px 0;
  text-align: center;
  text-shadow: 0 0 5px rgba(255,0,0,0.5);
}

.search-container {
  position: relative;
  width: 100%;
  z-index: 10;
}

.search-results {
  background: #222;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  left: 0;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
}

.search-result-item {
  align-items: center;
  background-color: #222;
  border-bottom: 1px solid #000;
  color: #eee;
  cursor: pointer;
  display: flex;
  padding: 6px;
  transition: background-color 0.2s;
}

.search-result-item:hover {
  background-color: #333;
  color: #000;
}

.search-result-artwork {
  border-radius: 4px;
  height: 40px;
  margin-right: 12px;
  width: 40px;
}

.search-result-info {
  flex-grow: 1;
  min-width: 0;
}

.search-result-title {
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  font-weight: 500;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-result-artist {
  color: #666;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected-song-artwork {
  border-radius: 4px;
  flex-shrink: 0;
  height: 40px;
  margin-right: 10px;
  overflow: hidden;
  width: 40px;
}

.selected-song-artwork img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.search-wrapper {
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
}

.artwork-preview {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.file-upload-container {
  text-align: center;
  width: 200px;
}

.file-input {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.search-input::placeholder {
  color: rgba(255, 240, 220, 0.4);
  font-family: "Courier New", monospace;
}

.search-input:focus {
  border-color: rgba(255, 240, 220, 0.4);
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.7);
  outline: none;
}

.file-input + label {
  align-items: center;
  background: #222;
  border: 1px solid rgba(255, 240, 220, 0.3);
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
  color: rgb(255, 240, 220);
  cursor: pointer;
  display: inline-flex;
  font-family: "Courier New", monospace;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  padding: 8px;
  text-align: center;
  transition: all 0.2s ease;
  width: 200px;
}

.file-input + label:hover {
  background: #222222;
  border-color: rgba(255, 240, 220, 0.5);
}

#root, .App {
  background: #222;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100vw;
}

body {
  background: #222;
  margin: 0;
  padding: 0;
}

.file-input.error {
  border-color: rgba(255, 0, 0, 0.5);
}

.submit-button.loading {
  cursor: wait;
  opacity: 0.7;
}

.search-results.loading {
  opacity: 0.7;
}

.submit-button:focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  outline: none;
}

.hint-container {
  animation: fadeIn 0.5s ease-out;
  bottom: 2rem;
  left: 50%;
  max-width: 600px;
  position: fixed;
  transform: translateX(-50%);
  width: 90%;
  z-index: 1000;
}

.hint-box {
  background: #222E;
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
  color: rgb(255, 240, 220);
  font-family: "Courier New", monospace;
  padding: 1rem;
  position: relative;
}

.hint-close {
  align-items: center;
  background: none;
  border: none;
  border-radius: 4px;
  color: rgba(255, 240, 220, 0.6);
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  transition: color 0.2s ease;
}

.hint-close:hover {
  background: rgba(255, 240, 220, 0.1);
  color: rgba(255, 240, 220, 1);
}

.hint-header {
  color: rgb(255, 240, 220);
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  padding-right: 2rem;
}

.hint-text {
  color: rgba(255, 240, 220, 0.9);
  font-size: 0.9rem;
  line-height: 1.4;
}

from {
  opacity: 0;
  transform: translate(-50%, 20px);
}

to {
  opacity: 1;
  transform: translate(-50%, 0);
}

.mobile-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  width: 70%;
  padding-left: 15%;
  padding-top: 6rem;
}

.search-section {
  position: relative;
  width: 100%;
}

.mobile-search-container {
  position: relative;
  width: 100%;
}

.mobile-search-results {
  background: #222;
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  left: 0;
  margin-top: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1000;
}

.mobile-year-wheel-section {
  backdrop-filter: blur(10px);
  background: rgba(34, 34, 34, 0.8);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  max-width: 500px;
  padding: 1.5rem;
  width: 100%;
}

.year-wheel-explanation {
  color: rgb(255, 240, 220);
  font-family: 'Courier New', monospace;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  opacity: 0.9;
  text-align: center;
}

.mobile-year-wheels {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  padding: 0.5rem;
}

.year-wheel-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.year-wheel-label {
  color: rgb(255, 240, 220);
  font-family: 'Courier New', monospace;
  font-size: 0.9rem;
  opacity: 0.8;
  text-align: center;
}

.mobile-submit-button {
  background: #222;
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.5);
  color: rgb(255, 240, 220);
  cursor: pointer;
  font-family: "Courier New", monospace;
  font-size: 14px;
  height: 40px;
  letter-spacing: 1px;
  max-width: 500px;
  padding: 6px;
  transition: all 0.2s ease;
  width: 100%;
}

.mobile-submit-button:hover:not(:disabled) {
  background: #222222;
  border-color: rgba(255, 240, 220, 0.5);
}

.mobile-submit-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.mobile-hint-container {
  bottom: 1rem;
  left: 1rem;
  position: fixed;
  right: 1rem;
  z-index: 1000;
}

.search-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: 100%;
}

.upload-button {
  background: #222;
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 6px;
  color: rgb(255, 240, 220);
  cursor: pointer;
  height: 41px;
  padding: 8px;
  transition: all 0.2s ease;
}

.upload-button:hover {
  background: #222222;
  border-color: rgba(255, 240, 220, 0.5);
}

.file-preview {
  align-items: center;
  background: #333;
  border-radius: 4px;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/* Audio player styles */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #666;
  border-radius: 2px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}
