/* VHS/CRT Overlay Styles */
html, body {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.App {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  /* Ensure content fills viewport without scrolling */
  display: flex;
  flex-direction: column;
}

.vhs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
}

.vhs-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(18, 16, 16, 0) 50%,
    rgba(0, 0, 0, 0.25) 50%
  );
  background-size: 100% 3px;
  z-index: 2;
}

.vhs-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.075),
    rgba(0, 0, 0, 0.075) 1px,
    transparent 1px,
    transparent 2px
  );
  animation: vhsScan 0.15s linear infinite;
}

@keyframes vhsScan {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.noise {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noise)' opacity='0.1'/%3E%3C/svg%3E");
  pointer-events: none;
  opacity: 0.25;
  z-index: 1001;
}

.video-container {
  position: relative;
}

.video-container video {
/*  filter: saturate(120%) contrast(110%); */
}

.error-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  z-index: 1000;
}

/* Improve transitions */
.transitioning {
  pointer-events: none;
}

/* General loading styles that might be used across components */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.password-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.deck-container {
  width: 600px;
  background: linear-gradient(45deg, #333, #222);
  padding: 40px;
  border-radius: 12px;
  box-shadow:
    0 0 0 1px rgba(255,255,255,0.1),
    0 20px 40px rgba(0,0,0,0.4);
}

.deck-frame {
  background: #1a1a1a;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid rgba(255,255,255,0.1);
}

.deck-display {
  background: #000;
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 30px;
}

.vu-meters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 40px;
}

.vu-meter {
  width: 120px;
  height: 60px;
  background: #111;
  border-radius: 4px;
  position: relative;
  border: 1px solid #333;
}

.led-display {
  color: #ff8800;
  font-family: "Digital-7", monospace;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 20px;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(255,136,0,0.5);
}

.password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.password-input {
  width: 80%;
  padding: 12px;
  background: #111;
  border: 1px solid #333;
  border-radius: 4px;
  color: #ff8800;
  font-family: "Digital-7", monospace;
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 4px;
  outline: none;
  transition: all 0.3s ease;
}

.password-input:focus {
  border-color: #ff8800;
  box-shadow: 0 0 15px rgba(255,136,0,0.2);
}

.password-submit {
  padding: 12px 40px;
  background: linear-gradient(to bottom, #333, #222);
  border: 1px solid #444;
  border-radius: 4px;
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-size: 0.9rem;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.password-submit:hover {
  background: linear-gradient(to bottom, #444, #333);
  border-color: #ff8800;
}

.password-error {
  color: #ff4444;
  font-size: 0.9rem;
  text-align: center;
}

.deck-controls {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.control-group {
  display: flex;
  gap: 20px;
}

.control-knob {
  width: 40px;
  height: 40px;
  background: linear-gradient(45deg, #222, #333);
  border-radius: 50%;
  border: 1px solid #444;
}

@keyframes vuMeter {
  0% { transform: rotate(-20deg); }
  50% { transform: rotate(20deg); }
  100% { transform: rotate(-20deg); }
}

.vu-needle {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 2px;
  height: 40px;
  background: #ff8800;
  transform-origin: bottom center;
  animation: vuMeter 2s ease-in-out infinite;
}

.vu-scale {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 1px;
  background: #333;
}

/* AccountStatus styles */
.account-status {
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1005;
  font-family: 'Courier New', monospace;
}

.credits-display {
  background: rgba(34, 34, 34, 0.8);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  color: rgb(255, 240, 220);
  font-size: 0.9rem;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.auth-button {
  background: rgba(34, 34, 34, 0.8);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  color: rgb(255, 240, 220);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.auth-button:hover {
  background: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

/* Auth Modal styles */
.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: blur(10px);
}

.auth-modal {
  background: #1a1a1a;
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  padding: 2rem;
  color: rgb(255, 240, 220);
  font-family: 'Courier New', monospace;
}

.auth-modal-header {
  margin-bottom: 2rem;
  text-align: center;
}

.auth-modal-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.auth-modal-subtitle {
  font-size: 0.9rem;
  color: rgba(255, 240, 220, 0.7);
}

.auth-option {
  background: rgba(34, 34, 34, 0.8);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.auth-option:hover {
  background: rgba(42, 30, 20, 0.95);
  border-color: rgba(255, 240, 220, 0.3);
}

.auth-option-icon {
  width: 24px;
  height: 24px;
}

.auth-divider {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  color: rgba(255, 240, 220, 0.4);
}

.auth-divider::before,
.auth-divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: rgba(255, 240, 220, 0.2);
}

.auth-divider span {
  padding: 0 1rem;
}

.auth-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: rgba(255, 240, 220, 0.4);
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s ease;
}

.auth-close:hover {
  color: rgb(255, 240, 220);
}

.email-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.auth-input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(34, 34, 34, 0.8);
  border: 1px solid rgba(255, 240, 220, 0.2);
  border-radius: 4px;
  color: rgb(255, 240, 220);
  font-size: 1rem;
}

.auth-form-title {
  color: rgb(255, 240, 220);
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.auth-submit {
  width: 100%;
  padding: 0.75rem;
  background: rgba(42, 30, 20, 0.95);
  border: 1px solid rgba(255, 240, 220, 0.3);
  border-radius: 4px;
  color: rgb(255, 240, 220);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.auth-toggle {
  width: 100%;
  padding: 0.75rem;
  background: transparent;
  border: none;
  color: rgba(255, 240, 220, 0.7);
  font-size: 0.9rem;
  cursor: pointer;
}

.auth-error {
  color: #ff4444;
  font-size: 0.9rem;
  text-align: center;
}

/* Navigation Bar Styles */
.nav-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(34, 34, 34, 0.9);
  border-top: 1px solid rgba(255, 240, 220, 0.2);
  backdrop-filter: blur(10px);
  padding: 1rem;
  z-index: 1002; /* Above the VHS overlay */
}

.nav-bar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-buttons {
  display: flex;
  gap: 1.5rem;
}

.nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: rgba(255, 240, 220, 0.6);
  font-family: "Courier New", monospace;
  transition: all 0.2s ease;
  cursor: pointer;
}

.nav-button:hover {
  background: rgba(255, 240, 220, 0.1);
  color: rgba(255, 240, 220, 1);
}

.nav-button.active {
  background: rgba(255, 240, 220, 0.2);
  color: rgba(255, 240, 220, 1);
}

.nav-button-label {
  font-size: 0.75rem;
  letter-spacing: 0.5px;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.social-link {
  color: rgba(255, 240, 220, 0.6);
  transition: all 0.2s ease;
  padding: 0.5rem;
  border-radius: 50%;
}

.social-link:hover {
  color: rgba(255, 240, 220, 1);
  background: rgba(255, 240, 220, 0.1);
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .nav-bar {
    padding: 0.75rem;
  }
  
  .nav-buttons {
    gap: 1rem;
  }
  
  .nav-button {
    padding: 0.5rem;
  }
  
  .social-links {
    gap: 0.5rem;
  }
}

/* Ensure main content doesn't get hidden behind nav bar */
.App {
  padding-bottom: 5rem;
}
